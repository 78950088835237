import ReactPaginate from "react-paginate";
import Styles from "../../StylesConstant/PaginationStyle.module.scss";
export const PaginateComponent = ({ items, page, handlePageChange }) => {
  //to get number of pages by dividing "count" on 10 and rounded it up
  const paginationCount = items?.count
    ? items.count / 10
    : Array.isArray(items) && items.length
    ? items.length / 5
    : 1;
  const paginationRound = Math.ceil(paginationCount);
  return (
    <ReactPaginate
      // breakLabel="..."
      nextLabel=">"
      onPageChange={handlePageChange}
      pageRangeDisplayed={5}
      pageCount={paginationRound}
      forcePage={page - 1}
      previousLabel="<"
      // renderOnZeroPageCount={null}
      disableInitialCallback={true}
      // marginPagesDisplayed={10}
      className={Styles.main_paginate}
      pageClassName={Styles.page_style}
      previousClassName={Styles.arrow_style}
      nextClassName={Styles.arrow_style}
      activeClassName={Styles.page_active_style}
    />
  );
};
