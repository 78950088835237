import { EndPoints } from "./EndPoints";
import { Network } from "./Network";

export class StudentService {
  static Students(page, values) {
    let filterParams = `?page=${page}`;
    filterParams = values?.search
      ? `${filterParams}&search=${values?.search}`
      : filterParams;
    filterParams = values?.class_name
      ? `${filterParams}&class_name=${values?.class_name}`
      : filterParams;
    filterParams = values?.grade
      ? `${filterParams}&grade=${values?.grade}`
      : filterParams;
      filterParams = values?.student_id
      ? `${filterParams}&student_id=${values?.student_id}`
      : filterParams;

    return Network.fetch(
      EndPoints.students.url(filterParams),
      {
        method: EndPoints.students.method,
      },
      true
    );
  }

  static editStudentAttendance(id) {
    return Network.fetch(
      EndPoints.editStudent.url,
      {
        body: JSON.stringify({
          user_id: id,
        }),
        method: EndPoints.editStudent.method,
      },
      true
    );
  }
}
