import { Dropdown, Image } from "react-bootstrap";
import Styles from "../../StylesConstant/MenuStyle.module.scss";
import { useLocation } from "react-router-dom";
import { AuthContextSession } from "../../Hooks/AuthContext";
import { useEffect, useState } from "react";
import logo_circle from "../../assets/images/logo_circle.png";
import { UserService } from "../../apis/UserService";
export const Header = () => {
  const location = useLocation();
  const { setIsLogin } = AuthContextSession();
  const [adminData, setAdminData] = useState();

  const handleLogout = async () => {
    localStorage.clear();
    setIsLogin(false);
  };

  const userDetail = () => {
    UserService.userDetail()
      .then((res) => {
        setAdminData(res);
      })
      .catch((err) => {
        console.log("userDetail: ", err);
      });
  };

  const handleNav = () => {
    const parts = location.pathname.split("/").filter((part) => part !== ""); // Split the string and remove empty parts

    const resultString = parts.join(" | "); // Join the parts with " | "
    return resultString;
  };
  useEffect(() => {
    userDetail();
  }, []);
  return (
    <section className={`${Styles.main_header}`}>
      <div className="container">
        <div className={`${Styles.flex_space}`} style={{ height: 88 }}>
          <h3>{handleNav()}</h3>

          <div className={`${Styles.flex_center}`}>
            <div>
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className={`${Styles.dropdown_class}`}
                >
                  <Image
                    src={adminData?.profile_image || logo_circle}
                    roundedCircle
                  />
                  <p>{adminData?.full_name}</p>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
