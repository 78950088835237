import { EndPoints } from "./EndPoints";
import { Network } from "./Network";

export class UserService {
  static login(values) {
    return Network.fetch(
      EndPoints.login.url,
      {
        body: JSON.stringify(values),
        method: EndPoints.login.method,
      },
      false
    );
  }

  static userDetail() {
    return Network.fetch(
      EndPoints.userDatail.url,
      {
        method: EndPoints.userDatail.method,
      },
      true
    );
  }
}
