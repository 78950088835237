import { ButtonComponent } from "../ButtonComponent/ButtonComponent";
import btnStyles from "../../StylesConstant/ButtonStyle.module.scss";
import { InputComponent } from "../InputsComponent/InputComponent";
import inputStyle from "../../StylesConstant/InputStyle.module.scss";
import searchIcon from "../../assets/images/search.svg";
import filterIcon from "../../assets/images/filter.svg";
import React, { useState } from "react";

export const SearchComponent = ({ fromPage, handleFilter }) => {
  const [filter, setFilter] = useState({
    search: null,
    class_name: null,
    grade: null,
    student_id: null,
    menu: null,
  });

  return (
    <section style={{ marginBottom: "2rem" }}>
      <div className="row" style={{ marginBottom: "1rem" }}>
        <div className={`col-md-6`}>
          <div className="d-flex align-items-center">
            <InputComponent
              placeholder={`Search for ${fromPage}`}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleFilter(filter);
                }
              }}
              inputType="text"
              icon={
                <img
                  src={searchIcon}
                  alt="search"
                  className={`${inputStyle.searchIcon}`}
                />
              }
              customForm_style={{
                marginBottom: 0,
                width: "60%",
                marginRight: 24,
              }}
              isSearch
              value={filter.search}
              onChange={(e) => setFilter({ ...filter, search: e.target.value })}
            />

            <ButtonComponent
              text="Filter"
              customStyle={btnStyles.filterBtnWhite}
              isFill
              handleClick={() => handleFilter(filter)}
              icon={
                <img src={filterIcon} alt="filter" style={{ marginRight: 8 }} />
              }
            />
          </div>
        </div>

        <div className="d-flex align-items-center mt-3 col-md-8">
          <InputComponent
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleFilter(filter);
              }
            }}
            placeholder={`Class Name`}
            inputType="text"
            customForm_style={{
              marginBottom: 0,
              width: "39.5%",
              marginRight: 24,
            }}
            customInputClass={inputStyle.whiteInput}
            value={filter.class_name}
            onChange={(e) =>
              setFilter({ ...filter, class_name: e.target.value })
            }
          />

          <InputComponent
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleFilter(filter);
              }
            }}
            placeholder={`Grade`}
            inputType="text"
            customForm_style={{
              marginBottom: 0,
              width: "39.5%",
              marginRight: 24,
            }}
            customInputClass={inputStyle.whiteInput}
            value={filter.grade}
            onChange={(e) => setFilter({ ...filter, grade: e.target.value })}
          />
          <InputComponent
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleFilter(filter);
              }
            }}
            placeholder={`Student ID`}
            inputType="text"
            customForm_style={{
              marginBottom: 0,
              width: "39.5%",
              marginRight: 24,
            }}
            customInputClass={inputStyle.whiteInput}
            value={filter.student_id}
            onChange={(e) => setFilter({ ...filter, student_id: e.target.value })}
          />
        </div>
      </div>
    </section>
  );
};
