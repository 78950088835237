const Url = "https://backend-prod.gotag.co/api/";
export const EndPoints = {
  login: {
    url: `${Url}user/teacher/login/`,
    method: "POST",
  },

  userDatail: {
    url: `${Url}teacher/profile/`,
    method: "GET",
  },

  students: {
    url: (filterParams) => `${Url}teacher/students/${filterParams}`,
    method: "GET",
  },

  editStudent: {
    url: `${Url}teacher/record_attendance/`,
    method: "POST",
  },
};
