import { Route, Routes, useLocation } from "react-router-dom";
import { LoginPage } from "./Pages/AuthScreens/LoginPage";
import { MenuNav } from "./Pages/MenuScreens/MenuNav";
import menuStyle from "./StylesConstant/MenuStyle.module.scss";
import { AuthContextSession } from "./Hooks/AuthContext";
import { Header } from "./Pages/MenuScreens/Header";
import ProtectedRoute from "./Components/ProtectedRoutes";
import HomeScreen from "./Pages/Home/HomeScreen";

const RouteComponent = () => {
  const { isLogin } = AuthContextSession();
  const location = useLocation();

  return (
    <main>
      <>
        {isLogin && location.pathname !== "/" && <Header />}
        <div
          className={`${isLogin && location.pathname !== "/" ? "app" : ""} `}
        >
          <main
            className={`${
              isLogin && location.pathname !== "/" ? "app__content" : ""
            } `}
          >
            <Routes>
              <Route path="/" element={<LoginPage />} />

              <Route path="/login" element={<LoginPage />} />

              <Route
                path="/Home"
                element={
                  <ProtectedRoute isAuthenticate={isLogin}>
                    <HomeScreen />
                  </ProtectedRoute>
                }
              />

              {/* Teachers */}

              {/* <Route
                path="/Teachers"
                element={
                  <ProtectedRoute isAuthenticate={isLogin}>
                    <TeacherListScreen />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/Teachers/detail"
                element={
                  <ProtectedRoute isAuthenticate={isLogin}>
                    <TeacherDetail />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/Teachers/detail/:type"
                element={
                  <ProtectedRoute isAuthenticate={isLogin}>
                    <AddOrEditTeacher />
                  </ProtectedRoute>
                }
              /> */}
            </Routes>
          </main>
        </div>
      </>
    </main>
  );
};
export default RouteComponent;
