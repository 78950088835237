import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children, isAuthenticate }) => {
  let location = useLocation();
  const token = localStorage.getItem("goTag_admin_token");
  if (!isAuthenticate && !token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else {
    return children;
  }
};

export default ProtectedRoute;
