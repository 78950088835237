import { Button } from "react-bootstrap"
import Styles from '../../StylesConstant/ButtonStyle.module.scss';
export const ButtonComponent = (props) => {
    return (

        <Button className={`${props?.isFill ? Styles.fillBtn : props?.isLink ? Styles.linkBtn : Styles.outlineBtn} ${props?.customStyle ? props?.customStyle : ''}`}
           style={props?.customCustomStyle}
           onClick={props.handleClick}
           type={props?.type ? props?.type : 'submit'}
           disabled={props.disabled}
           >
            {props?.icon}
            {props.text}
        </Button>
    )
}