import { Form } from "react-bootstrap"
import Styles from '../../StylesConstant/InputStyle.module.scss'

export const InputComponent = (props) => {
    return (
        <Form.Group className={`${Styles.formGroupStyle}`} style={props?.customForm_style} controlId="exampleForm.ControlInput1">
            {props?.label &&
                <Form.Label className={`${Styles.labelStyle}`} style={props?.customLabelStyle}>{props?.label}</Form.Label>
            }
            <div className="position-relative">
                <Form.Control
                   type={props?.inputType ? props?.inputType : 'text'}
                    placeholder={props?.placeholder}
                    className={`${props.isSearch ? Styles.searchInputStyle : props?.customInputClass ? props?.customInputClass : Styles.inputStyle}`} style={props?.customInput_style}
                    onChange={(e) => {
                        props?.handleChange(e.target.value);
                    }}
                    autoFocus={props?.isFocus ? props?.isFocus : false}
                    
                    {...props}
                />
                {props?.icon}
            </div>
        </Form.Group>
    )
}