import logo from './logo.svg';
import './App.css';
import RouteComponent from './RouteComponent';
import 'bootstrap/dist/css/bootstrap.min.css';
import './StylesConstant/globalStyle.scss'
import { Header } from './Pages/MenuScreens/Header';
import { Toaster } from 'react-hot-toast';

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import AuthContext from './Hooks/AuthContext';
import { useEffect, useState } from 'react';
function App() {
  const [isLogin, setIsLogin] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    autoLogin();
  }, [isLogin]);

  const handleLoader = (arg) => {
    setLoader(arg);
  };

  const autoLogin = async () => {
    const token = await localStorage.getItem("goTag_admin_token");
    if (token) {
      setIsLogin(true);
    } else {

      return;
    }
  };
  return (
    <AuthContext.Provider value={{ isLogin, setIsLogin, loader, handleLoader }}>
      <main>
        <Toaster />
        <RouteComponent />
      </main>
    </AuthContext.Provider>
  );
}

export default App;
