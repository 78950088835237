import { Image, Modal, Table } from "react-bootstrap";
import tableStyles from "../../StylesConstant/TableStyle.module.scss";
import { InputComponent } from "../../Components/InputsComponent/InputComponent";
import { SearchComponent } from "../../Components/SearchComponent/SearchComponent";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { StudentService } from "../../apis/StudentService";
import { toast } from "react-hot-toast";
import defaultImg from "../../assets/images/defaultImg.svg";
import { PaginateComponent } from "../../Components/PaginateSelectedComponent/PaginateComponent";
import { ButtonComponent } from "../../Components/ButtonComponent/ButtonComponent";
import btnStyles from "../../StylesConstant/ButtonStyle.module.scss";
import { AiFillCloseCircle } from "react-icons/ai";

export default function HomeScreen() {
  const { pageNum } = useParams();
  const [students, setStudents] = useState([]);
  const [studentsObj, setStudentsObj] = useState();
  const [page, setPage] = useState(pageNum || 1);
  const [filterAttr, setFilterAttr] = useState();
  const [isOpenModal, setIsOpenModal] = useState({ isOpen: false, user: null });
  const [tagObj, setTagObj] = useState({ tag_id: null, tag_type: null });

  const listStudents = () => {
    StudentService.Students(page, filterAttr)
      .then((res) => {
        setStudentsObj(res);
        setStudents(res.results);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const addTag = () => {
    let finalObj = { ...tagObj, user: isOpenModal.user };

    StudentService.addStudTag(finalObj)
      .then((res) => {
        setIsOpenModal({ isOpen: false, id: null });
        toast.success("Tag added sucessfully");
      })
      .catch((err) => {
        console.log("err of addTag: ", err);
        toast.error(err);
      });
  };

  useEffect(() => {
    listStudents();
  }, [page, filterAttr]);

  const handleStudentAttendanceChange = (id) => {
    StudentService.editStudentAttendance(id)
      .then((res) => {
        toast.success("Attendance updated sucessfully");
        listStudents();
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  return (
    <main>
      {/* section1 of cards */}
      <section>
        <SearchComponent
          fromPage="Student"
          handleFilter={(values) => {
            setPage(1);
            setFilterAttr(values);
          }}
        />
      </section>

      <section className={`${tableStyles.table_section} white_sec`}>
        <div className={`section_head_filter container`}>
          <h4>Students Information</h4>
        </div>
        <hr />
        <section>
          <Table
            responsive
            striped
            className={`${tableStyles.tableStyle} container`}
          >
            <thead>
              <tr>
                <th>Student Name</th>
                <th>ID</th>
                <th>Grade</th>
                <th>Class</th>
                <th>Age</th>
                <th>Gender</th>
                <th>Leave Method</th>
                <th>Status</th>
                <th>Tag</th>
              </tr>
            </thead>
            <tbody>
              {students.map((item, index) => (
                <tr
                  key={index.toString()}
                  className={`${tableStyles.body_tr_style}`}
                >
                  <td className={`${tableStyles.td_bold_link}`}>
                    <Link
                      to={`/Students/detail`}
                      state={{
                        detail: item,
                      }}
                    >
                      <>
                        <Image
                          src={
                            item?.profile_image
                              ? item?.profile_image
                              : defaultImg
                          }
                          roundedCircle
                          style={{ marginRight: 12, width: 40, height: 40 }}
                        />
                        {item.full_name}
                      </>
                    </Link>
                  </td>
                  <td>{item.student_id}</td>
                  <td>{item.grade || "-"}</td>
                  <td>{item.class_name || "-"}</td>
                  <td>{item.age || "-"}</td>
                  <td>{item.gender || "-"}</td>
                  <td>
                    <p
                      className={`${tableStyles.badge} ${
                        item.student_leave_method ===
                        item.default_student_leave_method
                          ? tableStyles.badgeGreen
                          : tableStyles.badgeYellow
                      }`}
                    >
                      {item.student_leave_method}
                    </p>
                  </td>
                  <td>
                    <p
                      className={`${tableStyles.badge} ${
                        item.status === "Absent"
                          ? tableStyles.badgeRed
                          : tableStyles.badgeGreen
                      }`}
                    >
                      {item.status}
                    </p>
                  </td>

                  <td>
                    {item.status === "Absent" && (
                      <ButtonComponent
                        text="Change To In School"
                        customStyle={btnStyles.outlineBtn}
                        customCustomStyle={{ marginRight: 16 }}
                        handleClick={() => {
                          handleStudentAttendanceChange(item.id);
                        }}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <PaginateComponent
            items={studentsObj}
            page={page}
            handlePageChange={(value) => {
              setPage(value.selected + 1);
            }}
          />
        </section>
      </section>

      {/* to handle nested route from parent to child */}
      {/* <Outlet context={[currentUser]}/> */}

      {/* modal of add tag */}

      <Modal
        size="smal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isOpenModal.isOpen}
      >
        <Modal.Body>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <AiFillCloseCircle
              color="#010066"
              style={{ width: 20, height: 20, cursor: "pointer" }}
              onClick={() => setIsOpenModal({ isOpen: false, user: null })}
            />
          </div>
          <div>
            <InputComponent
              label="Tag ID"
              isFocus={true}
              //   value={tagObj.tag_id}
              handleChange={(value) => setTagObj({ ...tagObj, tag_id: value })}
            />
            <InputComponent
              label="Tag Type"
              //   value={tagObj.tag_type}
              handleChange={(value) =>
                setTagObj({ ...tagObj, tag_type: value })
              }
            />
            <div style={{ marginTop: 56 }}>
              <ButtonComponent
                type="button"
                text="Save"
                isFill
                handleClick={() => addTag()}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </main>
  );
}
