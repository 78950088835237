import { useRef, useState } from "react";
import { Carousel, Form } from "react-bootstrap";
import Styles from "../../StylesConstant/AuthStyle.module.scss";
import { InputComponent } from "../../Components/InputsComponent/InputComponent";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { BsArrowRightCircle, BsArrowLeftCircle } from "react-icons/bs";
import inputStyles from "../../StylesConstant/InputStyle.module.scss";
import { ButtonComponent } from "../../Components/ButtonComponent/ButtonComponent";
import goLogo from "../../assets/images/logo.svg";
import btnStyles from "../../StylesConstant/ButtonStyle.module.scss";
import sliderImg1 from "../../assets/images/slider1.png";
import sliderImg2 from "../../assets/images/slider2.png";

import * as Yup from "yup";
import { Formik } from "formik";
import { UserService } from "../../apis/UserService";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { AuthContextSession } from "../../Hooks/AuthContext";

let dummyArr = [
  {
    imm: sliderImg1,
    title:
      "Experience the ultimate convenience in School, easily track Student's transactions.",
  },
  {
    imm: sliderImg2,
    title:
      "Experience the ultimate convenience in School, easily track Student's transactions.",
  },
];

const schema = Yup.object().shape({
  username: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters long")
    .max(20, "Password must not exceed 20 characters"),
});
export const LoginPage = () => {
  const [index, setIndex] = useState(0);
  const ref = useRef(null);
  const navigate = useNavigate();
  const { setIsLogin } = AuthContextSession();

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const onPrevClick = () => {
    ref.current.prev();
  };
  const onNextClick = () => {
    ref.current.next();
  };

  const handleLogin = (values) => {
    let data = {
      username: values.username,
      password: values.password,
    };
    UserService.login(data)
      .then((res) => {
        localStorage.setItem("goTag_admin_token", res?.access);
        toast.success("Logged Successfully!");
        setIsLogin(true);
        navigate("/Home");
      })
      .catch((err) => {
        console.log("errrrr: ", err);
        toast.error(err);
      });
  };

  const token = localStorage.getItem("goTag_admin_token");
  if (token) {
    return <Navigate to="/Home" replace />;
  }

  return (
    <main>
      <section className={`${Styles.main_container}`}>
        <div className={`row`}>
          {/* col1-form */}
          <div className={`col-md-6`}>
            <div className={`${Styles.login_div1}`}>
              <img src={goLogo} alt="logo" />
              <div className={`${Styles.title_div}`}>
                <h2>Welcome to GoTag!</h2>
                <h6>Login to continue</h6>
              </div>

              <div>
                <Formik
                  initialValues={{ username: "", password: "" }}
                  onSubmit={(values) => handleLogin(values)}
                  // validationSchema={schema}
                >
                  {({ values, errors, handleSubmit, handleChange }) => (
                    <>
                      <Form onSubmit={handleSubmit}>
                        <InputComponent
                          label="Username"
                          inputType="text"
                          name="username"
                          value={values.username}
                          handleChange={handleChange("username")}
                        />
                        <InputComponent
                          label="Password"
                          inputType="password"
                          name="password"
                          value={values.password}
                          // icon={
                          //   <AiOutlineEyeInvisible
                          //     className={`${inputStyles.iconAbsol}`}
                          //   />
                          // }
                          handleChange={handleChange("password")}
                        />
                        <div style={{ marginTop: 56 }}>
                          <ButtonComponent
                            text="Login"
                            isFill
                            handleClick={handleSubmit}
                          />
                          {/* <div style={{ marginTop: 20 }}>
                            <ButtonComponent
                              text="Forgot Password?"
                              customStyle={`${btnStyles.linkCustom}`}
                              isLink
                              type={"button"}
                            />
                          </div> */}
                        </div>
                      </Form>
                    </>
                  )}
                </Formik>
              </div>
            </div>
          </div>

          {/* col2-slider */}
          <div className={`col-md-6`}>
            <div className={`${Styles.login_div2}`}>
              <Carousel
                controls={false}
                indicators={false}
                activeIndex={index}
                onSelect={handleSelect}
                ref={ref}
              >
                {dummyArr.map((item, ind) => (
                  <Carousel.Item key={ind.toString()}>
                    <img
                      className={`${Styles.sliderImg}`}
                      src={item.imm}
                      alt="First slide"
                    />

                    <div className={`${Styles.imgOverlay}`}></div>
                    <Carousel.Caption>
                      <p>{item.title}</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                ))}
              </Carousel>
              <div className={`${Styles.carouselArrow}`}>
                <BsArrowLeftCircle
                  className={`${Styles.arrowIcon}`}
                  style={{ marginRight: 20 }}
                  onClick={onPrevClick}
                />
                <BsArrowRightCircle
                  className={`${Styles.arrowIcon}`}
                  onClick={onNextClick}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
